<template>
  <div class="form jn-form-style">
    <div class="form-item btn-wrap">
      <el-button
        type="primary"
        style="margin-left: 5px"
        icon="el-icon-refresh"
        class="refresh"
        @click="onHandleRefresh"
      ></el-button>
    </div>

    <div class="form-item">
      <el-input
        v-model="formData.fullname"
        placeholder="输入客户名称"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-input
        v-model="formData.mobile"
        placeholder="输入联系电话"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-select
        placeholder="选择集配中心"
        v-model="formData.businessLogisticId"
        filterable
        clearable
      >
        <el-option
          v-for="item of logisticsList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>

    <div class="form-item">
      <el-select
        filterable
        v-model="formData.logistics_id"
        clearable
        placeholder="选择城市仓"
      >
        <el-option
          v-for="item in cityStoreList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>

    <div class="form-item">
      <el-select
        v-model="formData.black_status"
        clearable
        placeholder="选择用户状态"
      >
        <el-option
          v-for="item in blackStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="form-item">
      <el-date-picker
        v-model="registerDate"
        @change="onChangeDate"
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="注册时间起"
        end-placeholder="注册时间止"
      >
      </el-date-picker>
    </div>

    <div class="form-item">
      <el-input
        v-model="formData.min_register_day"
        @blur="
          () =>
            (formData.min_register_day = utilsTool.filterUnNumber(
              formData.min_register_day
            ))
        "
        @change="onChangeAmt('min_register_day', 'max_register_day', 0)"
        placeholder="注册天数起"
        clearable
      ></el-input>
      <span class="link">-</span>
      <el-input
        v-model="formData.max_register_day"
        @blur="
          () =>
            (formData.max_register_day = utilsTool.filterUnNumber(
              formData.max_register_day
            ))
        "
        @change="onChangeAmt('min_register_day', 'max_register_day', 0)"
        placeholder="注册天数止"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-select
        v-model="formData.industry"
        clearable
        placeholder="选择从事行业"
      >
        <el-option
          v-for="item in professionList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <div class="form-item">
      <el-input
        v-model="formData.min_weight"
        @blur="
          () =>
            (formData.min_weight = utilsTool.filterUnNumber(
              formData.min_weight,
              true
            ))
        "
        @change="onChangeAmt('min_weight', 'max_weight', 1)"
        placeholder="下单总重起"
        clearable
      ></el-input>
      <span class="link">-</span>
      <el-input
        v-model="formData.max_weight"
        @blur="
          () =>
            (formData.max_weight = utilsTool.filterUnNumber(
              formData.max_weight,
              true
            ))
        "
        @change="onChangeAmt('min_weight', 'max_weight', 1)"
        placeholder="下单总重止"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-input
        v-model="formData.min_order_number"
        @blur="
          () =>
            (formData.min_order_number = utilsTool.filterUnNumber(
              formData.min_order_number
            ))
        "
        @change="onChangeAmt('min_order_number', 'max_order_number', 2)"
        placeholder="下单数量起"
        clearable
      ></el-input>
      <span class="link">-</span>
      <el-input
        v-model="formData.max_order_number"
        @blur="
          () =>
            (formData.max_order_number = utilsTool.filterUnNumber(
              formData.max_order_number
            ))
        "
        @change="onChangeAmt('min_order_number', 'max_order_number', 2)"
        placeholder="下单数量止"
        clearable
      ></el-input>
    </div>

    <div class="form-item">
      <el-select v-model="formData.is_vip" clearable placeholder="是否是会员">
        <el-option
          v-for="item in vipStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="form-item">
      <el-select v-model="formData.member_label_ids" multiple collapse-tags  filterable  clearable placeholder="选择标签">
        <el-option
          v-for="item in memberlabelalllist"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>

    <div class="form-item btn-wrap">
      <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
        >查询</el-button
      >
      <el-button
        @click="onHandleExport"
        :disabled="!tableData.length || disabledExport"
        type="warning"
        icon="el-icon-download"
        >导 出</el-button
      >
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import utilsTool from "@/utils/tools/tool";
import { postMemberListExport } from "@/api/export/center";
import {
  DISABLED_STATUS,
  MEMBER_VIP_STATUS,
  PROFESSION_MAP,
} from "../../utils/enum/index";

export default {
  name: "FormFilter",
  props: {
    /**
     * 携带参数
     */
    pageParams: {
      type: Object,
      default: () => {},
    },
    /**
     * 城市仓列表
     */
    cityStoreList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disabledExport: false,
      utilsTool,
      professionList: PROFESSION_MAP, // 职业下拉
      vipStatusList: Object.values(MEMBER_VIP_STATUS), // 会员状态列
      blackStatusList: Object.values(DISABLED_STATUS), // 状态枚举
      registerDate: [], // 日期对象
      logisticsList: [], // 集配中心列表
      formData: {
        fullname: "", // 客户名称
        mobile: "", // 联系电话
        businessLogisticId: "", // 集配中心
        logistics_id: "", // 城市仓id
        black_status: "", // 用户状态
        min_register_date: "", // 注册时间起
        max_register_date: "", // 注册时间止
        min_register_day: "", // 注册天数起
        max_register_day: "", // 注册天数止
        industry: "", // 从事行业
        min_weight: "", // 下单总重起
        max_weight: "", // 下单总重止
        min_order_number: "", // 下单数量起
        max_order_number: "", // 下单数量止
        is_vip: "", // 是否是会员
        member_label_ids:[]//会员标签列表
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      memberlabelalllist:[]
    };
  },
  created() {
this.getmemberlabelall()
  },

  methods: {
    getmemberlabelall(){
this.$api.member.memberlabelall().then(res=>{
  console.log(res)
  this.memberlabelalllist = res.data


})
    },
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.getAjaxLogisticsList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$parent.pageParams.page = 1;
      this.$parent.pageParams.pageSize = 10;
      this.registerDate = [];
      this.ajaxGetTableData();
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.$parent.pageParams.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.$parent.loadingData = true;
      const params = { ...this.formData, ...this.pageParams };
      try {
        const { data } = await this.$api.general.memberList(params);
        const { total, data: list, liquidate_amount_total: amountTotal } = data;
        this.total = total;
        this.tableData = list;
        const resData = {
          total,
          tableData: list,
          amountTotal,
        };
        // 更新父级列表数据
        Object.keys(resData).forEach((_key) => {
          this.$parent[_key] = resData[_key];
        });
      } catch (err) {
        console.log("ajax general.memberList err", err);
      } finally {
        this.$parent.loadingData = false;
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.$parent.pageParams.page = 1;
      this.$parent.pageParams.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.$parent.pageParams.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 日期发生改变
     */
    onChangeDate(data) {
      this.formData.min_register_date = data?.[0] || "";
      this.formData.max_register_date = data?.[1] || "";
    },
    /**
     * 金额发生改变
     * @param {String} first 第一个参数
     * @param {String} last 第二个参数
     * @param {Number} index 对于文案下标
     */
    onChangeAmt(first, last, index) {
      const nameMap = ["注册天数", "下单总重", "下单数量"];
      if (
        this.formData[first] &&
        this.formData[last] &&
        Number(this.formData[first]) > Number(this.formData[last])
      ) {
        this.$message.warning(
          `起始${nameMap[index]}，不得大于截止${nameMap[index]}`
        );
        this.formData[last] = "";
      }
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 新导出逻辑 */
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        // 组合需要的参数
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        await postMemberListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postMemberListExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
  },
};
</script>
