var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "multipleTable",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "fullname",
          align: "center",
          label: "客户名称",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.fullname || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "mobile",
          align: "center",
          label: "联系电话",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.mobile || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "name",
          align: "center",
          label: "城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (scope.row.logistics && scope.row.logistics.name) || "-"
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "shopname",
          align: "center",
          label: "店铺名称",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.shopname || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "industry",
          align: "center",
          label: "从事行业",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.professionName(scope.row.industry)) + " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "create_time",
          align: "center",
          label: "注册时间",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.create_time || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "days",
          align: "center",
          label: "注册天数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "average_order_number",
          align: "center",
          label: "日均下单数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_number",
          align: "center",
          label: "下单总数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "average_order_gross_weight",
          align: "center",
          label: "日均下单重量",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_gross_weight",
          align: "center",
          label: "下单总重",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "rep_rate",
          align: "center",
          label: "复购率(%)",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "labels",
          align: "center",
          label: "标签",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(_vm.labelstitle(scope.row.labels)) + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "black_status",
          align: "center",
          label: "状态",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.blackStatusList.find(
                        (item) => item.value === scope.row.black_status
                      ).label
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_vip",
          align: "center",
          label: "是否付费会员",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          fixed: "right",
          label: "操作",
          "min-width": "120",
          align: "center",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleStatus(scope.row)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        `${
                          scope.row.black_status ? "移出黑名单" : "加入黑名单"
                        }`
                      )
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }