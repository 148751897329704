<template>
  <section class="page-wrap">
    <!-- 顶部操作栏 start -->
    <section class="header">
      <form-filter
        ref="refFormFilter"
        :cityStoreList="cityStoreList"
        :pageParams="pageParams"
      ></form-filter>
    </section>
    <!-- 顶部操作栏 end -->

    <!-- 列表数据 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @on-change-status="onHandleBlack"
      ></table-list>
    </section>
    <!-- 列表数据 end -->

    <!-- 分页 start -->
    <section class="pagination">
      <el-pagination
        type="primary"
        :current-page="pageParams.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageParams.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </section>
    <!-- 分页 end -->

    <!-- 加入黑名单原因填写 start -->
    <add-black-dialog
      ref="refAddBlack"
      @on-change-status="onHandleChangeStatus"
    ></add-black-dialog>
    <!-- 加入黑名单原因填写 end -->
  </section>
</template>
<script>
import FormFilter from "./modules/form-filter/index.vue";
import TableList from "./modules/table-list/index.vue";
import AddBlackDialog from "./modules/add-black-dialog/index";
import { DISABLED_STATUS } from "./utils/enum/index";
import { postMemberBlack } from "@/api/system/index";

export default {
  name: "CommonMember",
  components: {
    FormFilter,
    TableList,
    AddBlackDialog,
  },
  data() {
    return {
      loadingData: true,
      cityStoreList: [], // 城市仓列表
      pageParams: {
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      amountTotal: "", // ???
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.$refs.refFormFilter.onInitData();
      this.ajaxGetCityList();
    },
    /**
     * 加入/移出黑名单
     */
    onHandleBlack(row) {
      const memberId = row.id;
      if (row.black_status === DISABLED_STATUS.yes.value) {
        // 移出黑名单
        const params = {
          member_id: memberId,
          black_status: DISABLED_STATUS.no.value,
        };
        this.ajaxPostMemberBlack(params);
      } else {
        this.$refs.refAddBlack.onInitData(memberId);
      }
    },
    /**
     * 移出黑名单
     */
    async ajaxPostMemberBlack(params) {
      try {
        await postMemberBlack(params);
        const emitData = {
          id: params.member_id,
          status: DISABLED_STATUS.no.value,
        };
        this.onHandleChangeStatus(emitData);
        this.$message.success("解除成功！");
      } catch (err) {
        console.log("ajax postMemberBlack err", err);
      }
    },
    /**
     * 修改接口成功后的数据（省去刷新一次列表的成本）
     */
    onHandleChangeStatus({ id, status }) {
      let index = this.tableData.findIndex((item) => item.id === id);
      this.tableData[index]["black_status"] = status;
    },
    /**
     * 获取城市仓下拉列
     */
    async ajaxGetCityList() {
      try {
        const { data } = await this.$api.general.logisticsMember();
        this.cityStoreList = data;
      } catch (err) {
        console.log("ajax general.logisticsMember err", err);
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.$refs.refFormFilter.onHandleSizeChange(val);
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.$refs.refFormFilter.onHandleCurrentChange(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
