/** 启用状态 */
export const DISABLED_STATUS = {
  no: {
    value: 0,
    label: '可用'
  },
  yes: {
    value: 1,
    label: '禁用'
  }
}

/** 是否付费会员 */
export const MEMBER_VIP_STATUS = {
  no: {
    value: 0,
    label: '否'
  },
  yes: {
    value: 1,
    label: '是'
  }
}

/** 职业 */
export const PROFESSION_MAP = [
  {
    value: 1,
    label: "中大型商超",
  },
  {
    value: 2,
    label: "水果生鲜店",
  },
  {
    value: 3,
    label: "企事业单位采购",
  },
  {
    value: 4,
    label: "二批商户",
  },
  {
    value: 5,
    label: "团购/团购领域",
  },
  {
    value: 99,
    label: "以上都不是",
  }
]

