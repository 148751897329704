var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "会员管理",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: {
                "label-width": "100px",
                model: _vm.formData,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "blackRemarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      minlength: 1,
                      maxlength: 1000,
                      placeholder: "请输入加入黑名单原因",
                    },
                    model: {
                      value: _vm.formData.blackRemarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "blackRemarks", $$v)
                      },
                      expression: "formData.blackRemarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }