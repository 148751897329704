<template>
  <el-dialog
    title="会员管理"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    center
  >
    <div class="contents">
      <el-form
        ref="refForm"
        label-width="100px"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="备注" prop="blackRemarks">
          <el-input
            type="textarea"
            :rows="3"
            :minlength="1"
            :maxlength="1000"
            v-model="formData.blackRemarks"
            placeholder="请输入加入黑名单原因"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { DISABLED_STATUS } from "../../utils/enum/index";
import { postMemberBlack } from "@/api/system/index";

export default {
  name: "AddBlackDialog",
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      memberId: "",
      formData: {
        blackRemarks: "",
      },
      formRules: {
        blackRemarks: [
          { required: true, message: "请输入加入黑名单原因", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (value?.trim()?.length) {
                callback();
              } else {
                callback(new Error("请至少输入一个字符！"));
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(id) {
      // this.formData.blackRemarks = "";
      this.memberId = id;
      this.dialogVisible = true;
      this.$refs.refForm?.resetFields();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.$refs.refForm.clearValidate();
    },
    /**
     * 提交数据
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        const params = {
          member_id: this.memberId,
          black_status: DISABLED_STATUS.yes.value,
          black_remarks: this.formData.blackRemarks,
        };
        // console.log("🆒 onHandleSubmit", params);
        this.ajaxPostMemberBlack(params);
      });
    },
    /**
     *  加入黑名单
     */
    async ajaxPostMemberBlack(params) {
      try {
        await postMemberBlack(params);
        this.$message.success("设置成功！");
        const emitData = {
          id: this.memberId,
          status: DISABLED_STATUS.yes.value,
        };
        this.$emit("on-change-status", emitData);
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postMemberBlack err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>
