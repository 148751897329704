<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    ref="multipleTable"
    tooltip-effect="dark"
    style="width: 100%"
  >
    <el-table-column
      prop="fullname"
      align="center"
      label="客户名称"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.fullname || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="mobile"
      align="center"
      label="联系电话"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.mobile || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="name"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{
          (scope.row.logistics && scope.row.logistics.name) || "-"
        }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="shopname"
      align="center"
      label="店铺名称"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.shopname || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="industry"
      align="center"
      label="从事行业"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ professionName(scope.row.industry) }}
      </template>
    </el-table-column>

    <el-table-column
      prop="create_time"
      align="center"
      label="注册时间"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.create_time || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="days"
      align="center"
      label="注册天数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="average_order_number"
      align="center"
      label="日均下单数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="order_number"
      align="center"
      label="下单总数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="average_order_gross_weight"
      align="center"
      label="日均下单重量"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="order_gross_weight"
      align="center"
      label="下单总重"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="rep_rate"
      align="center"
      label="复购率(%)"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="labels"
      align="center"
      label="标签"
      min-width="100"
    >
      <template slot-scope="scope">
        {{labelstitle(scope.row.labels) }}
      </template>
    </el-table-column>

    <el-table-column
      prop="black_status"
      align="center"
      label="状态"
      min-width="100"
    >
      <template slot-scope="scope">
        {{
          blackStatusList.find((item) => item.value === scope.row.black_status)
            .label
        }}
      </template>
    </el-table-column>

    <el-table-column
      prop="is_vip"
      align="center"
      label="是否付费会员"
      min-width="100"
    >
    </el-table-column>

    <el-table-column fixed="right" label="操作" min-width="120" align="center">
      <template slot-scope="scope">
        <el-button
          @click="onHandleStatus(scope.row)"
          type="text"
          size="small"
          >{{
            `${scope.row.black_status ? "移出黑名单" : "加入黑名单"}`
          }}</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { DISABLED_STATUS, PROFESSION_MAP } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      blackStatusList: Object.values(DISABLED_STATUS), // 状态枚举
    };
  },
  computed: {
    /**
     * 从事行业
     */
    professionName() {
      return (data) => {
        const res = "-";
        // 去掉最后一项
        const list = PROFESSION_MAP.slice(0, PROFESSION_MAP.length - 1);
        const find = list.find((item) => item.value === data)?.label;
        return find || res;
      };
    },
  },
  methods: {
    labelstitle(e){
return e.map(item=>item.name).join(',')
    },
    /**
     * 加入/移出黑名单
     */
    onHandleStatus(row) {
      this.$emit("on-change-status", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
