import { BASE } from "../index";
import axios from "../http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-会员管理-普通会员-设置黑名单
 * @param {*} params
 */
export const postMemberBlack = (params = {}) => {
  return axios.post(`${BASE_URL}/member/black`, params);
};
